<template>
  <div>
    <SaleForm
      :operation="operation"
      movement_stock="1"
      movement_stock_type="in"
      movement_finance="1"
      movement_finance_type="in"
      :id="this.$route.params.id"
    />
  </div>
</template>

<script>
import SaleForm from "@/components/sales/forms/SaleForm.vue";

export default {
  components: { SaleForm },

  data: () => ({
    operation: {
      id: 2,
      description: "Devolução",
      movement_stock: "1",
      movement_stock_type: "in",
      movement_finance: "1",
      movement_finance_type: "out",
      icon: "mdi-cart-minus",
    },
  }),

  created() {
    this.$store.commit("saleForm/reset");
  },

  async mounted() {
    if (this.$route.params.id) {
      this.$store.dispatch("saleForm/loadSale", this.$route.params.id);
    }

    // if (this.$route.query.fromOrder) {
    //   // laod products
    //   //this.$refs.orderForm.loadProductsFromOrder(this.$route.query.fromOrder)
    // }
  },
};
</script>

<style></style>
